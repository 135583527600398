/* @import url('bootstrap.min.css'); */

/* @import url("https://fonts.googleapis.com/css2?family=El+Messiri&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Lora&display=swap"); */

body {
  margin: 0;

  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  background-color: #f7f7f7;
}

.card-body h6{
  margin-top: 0px !important;
  font-size: 14px;
}
#cardLink{
  color: black !important;
  text-decoration: none;
}
.accordion-body img{height: auto;max-width: 100% !important;margin-bottom: 10px;margin-top: 10px;border-radius: 10px;}
#cardLink .btn{
 color: white;
}

#introDiv{
  /* background: #000; */
}
#introDiv img{
  height: auto;max-width: 100% !important

}
a{
  word-wrap: break-word !important;
}

.col-md-3 #cardLink:hover{
  background: #000 !important;
}
#appLogos{
text-align: center;
background: white;
}

#appLogos img{
height: 80px;
padding: 10px;
}

#appLogoimg2{
  height: 90px !important;
}
strong{
  text-transform: capitalize;
  text-decoration: underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New";
}
.panel-heading{
  background-color: red;
  font-size: 20px;
  text-transform: capitalize;
}

.accordion p{
  padding:0px !important
}
.card p{
  padding: 3px;
}

.card .btn{
  background-color: #171717;
  border: 1px solid #171717;
  border-radius: 0;
}

.card .btn :active :focus{
  background-color: #171717;

}

.card{
  
  border: 1px solid#59be41;
  margin: 20px;
}

.card h6{
  margin-top: 0;
}

.card-header{
  background-color: #59be41;
  font-size: 20px;
  text-transform: capitalize;
}

p>img,ul>img{
  padding: 0px;
  margin: 0px;
}

.btn.btn-primary {
  color: #fff;
  background-color:#171717;
  border-color:#171717;
  transition: .5s;
}
.btn.btn-primary.focus,
.btn.btn-primary:focus {
  color: #fff;
  background-color:#171717;
  border-color:#171717;
  outline: none;
  box-shadow: none;
}
.btn.btn-primary:hover {
  color: #171717;
  background-color:transparent;
  border-color:#171717;
  outline: none;
  box-shadow: none;
  letter-spacing: 1px;
}
.btn.btn-primary.active,.btn.btn-primary:active {
  color: #fff;
  background-color:#171717;
  border-color:#171717;
  outline: none;
}
.btn.btn-primary.active.focus,
.btn.btn-primary.active:focus,
.btn.btn-primary.active:hover,
.btn.btn-primary:active.focus,
.btn.btn-primary:active:focus,
.btn.btn-primary:active:hover {
  color: #fff;
  background-color:#171717;
  border-color:#171717;
  outline: none;
  box-shadow: none;
}
#appContainer ul{
  margin-top: 20px;
  font-size: 17px;
  /* font-style: italic; */
}
.navbar,
.navbar-brand {
  /* background-color: #ad9957; */
  background-color: #24471be1;

  margin: 0;
  z-index: 100;
}

.fixed-top {
  background-color: #2c7e18;
  box-shadow: 5px 10px 18px #52525275;
}

.fixed-top a {
  color: black;
}

.navbar-nav,
.navbar-collapse,
.nav-link {
  padding: 5px;
}

#myNav .navbar-collapse a {
  color: white;
  /* padding-bottom: 10px; */
  font-size: 15px;
  padding-left: 10px;
  /* text-transform: uppercase; */
  font-weight: bold;
}

.navbar-toggler {
  float: right;
  background-color: white;
}

body,
html {
  height: 100%;
  font-family: 'Roboto', sans-serif !important;
}

span[style]{
  font-family: 'Roboto', sans-serif !important;
  font-size: 16px !important;
}

#appLogoDiv {
  padding: 15px;
  text-align: center;
  background: white;
}

#appLogoDiv a {
  text-decoration: none;
  color: #353535;
}

#appLogoDiv a:hover {
  text-decoration: none;
  color: #59be41;
}

#appLogo {
  /* font-family: "El Messiri", sans-serif; */
  font-size: 30px;
}

.navbar-brand {
  font-family: 'Roboto', sans-serif;
  font-size: 25px;
  margin-left: 20px;
}

#myNav .collapse .dropdown-menu {
  margin-top: 15px;
}

#myNav .collapse .dropdown-menu a {
  color: white;
  margin-bottom: 10px;
  padding-bottom: 2px;
}

#myNav .collapse .dropdown-menu a:hover {
  color: black;
}

#myNav .collapse .dropdown-menu .dropdown-item {
  display: block;
}

#myNav .collapse .dropdown-menu .active {
  color: black;
  background-color: rgba(255, 255, 255, 0.774);
}

#myNav .active {
  color: white;
  background-color: #353535;
}

#myNav .navbar .open .dropdown-toggle {
  color: white;
  background-color: #353535;

  border-radius: 4px;
}

#myNav .collapse .dropdown-menu {
  background-color: #353535;
  max-height: 350px;
  overflow: auto;

}

#other-link-dropdown .dropdown-menu{
  margin-left: -150px;
}

#headerImg {
  background-position: center;
  width:100;
  height:250px;
  margin-top: -67px;
}

#carouselExampleFade {
  margin-top: -67px;
  width: 100%;
}

#carouselExampleFade img {
  height: 315px;
}

#headerHeading {
  bottom: 10px;
  position: absolute;
  background-color: #f7f7f7;
  /* left: 25px; */
  color: black;
  padding: 20px;
  font-size: 15px;
  font-weight: bold;
  border-left: 10px solid #59be41;
  margin-left: 15px;
  text-transform: uppercase;
  letter-spacing: 2px;
  
}

p ,center{
  padding-left: 20px;
  padding-right: 20px;
  /* font-family: "Lora", serif; */
  padding-bottom: 10px;
  font-size: 16px;
}

#footer {
  background-color: rgba(0, 0, 0, 0.705);
  color: white;
}
#footer .col-sm-9{
  margin-bottom: 100px;

}
#footer a {
  display: block;
  color: white;
  text-decoration: none;
  /* padding-left: 5px; */
  padding-bottom: 5px;
}

#footer a:hover {
  background-color: #353535;
}

.customdropdown {
  position: relative;
  display: inline-block;
}

.customdropdown-content {
  display: none;
  position: absolute;
  color: white;
  background-color: rgb(212, 39, 39);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;

  z-index: 1;
}

.customdropdown:hover .customdropdown-content {
  display: block;
}

#imageRowimg{
  transition: transform .5s; 
  opacity: .93;
}


#imageRowimg:hover{
  transform: scale(1.1);
  cursor: pointer;
  opacity: 1;
}

#sideMenu {
  border-left: 1px solid #59be41;
  padding: 10px;
  overflow: auto;
}

#sideMenu h5 {
  padding: 20px;

}

#sideMenu a {
  display: block;
  transition: .5s;
  color: rgb(0, 0, 0);
  padding-bottom: 10px;
  font-size: 13px;
  padding-left: 10px;
  text-decoration: none;
  text-transform: uppercase;
  margin-bottom: 5px;
}



.selected ,#childLinks .selected{
color: red;
  font-weight: bold; 
  border-left: 2px solid #59be41;
  background-color: #eeeeee;
}

#sideMenu #childLinks a{
font-size: 11px;
  
}

#sideMenu a:hover {
  color: #59be41;
  font-weight: bold;
  margin-left: 10px;
  border-left: 2px solid #59be41;
  background-color: #eeeeee;
}

#childLinks {
  margin-left: 20px;
}

#childLinks a {
  font-size: 13px;
}

#languageBtn{
  z-index: 1000; 
  position: fixed;
  bottom: 30px; 
  right: 38px; 
  font-size: 13px 
}

p{
  padding: 11px;
}


@media (max-width:470px) {
  #appLogos img{
    height: 95px;
   padding: 30;
    }
}

@media (max-width: 991.98px) {

#imageRowimg:hover{
  transform: scale(1);
  cursor: pointer;
  opacity: 1;
}

  .card{
  
    border: 1px solid#59be41;
    margin-left: 0px;
    margin-right: 0;
  }
  
  #languageBtn{
    z-index: 1000; 
    position: fixed;
    bottom: 30px; 
    right: 24px; 
    font-size: 13px 
  }
  
  #myNav .collapse .dropdown-menu {
    background-color: #353535;
    margin-left: 0;
    overflow: auto;
  
  }



  #myNav .collapse .dropdown-menu #childLinks{
    margin-left: 10px;
    
  }
  #headerImg {
    margin-top: 0px;
  }
  .navbar-brand {
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    margin-left: 8px;
  }

  #carouselExampleFade {
    margin-top: 0;
    width: 100%;
  }

  #carouselExampleFade img {
    height: 300px;
  }


  #headerHeading {
    padding: 10px;
    left: 5px;
    font-size: 15px;
  }

  p {
    padding: 5px;
  }

  #appLogoDiv {
    padding: 20px;
    text-align: center;
  }

  #appLogo {
    
  }

  #myNav .navbar-collapse a {
    font-size: 14px;
  }

  #sideMenu {
    margin-top: 50px;
  }
}

.table {
  margin-bottom: 50px;
  /* background-color: #eee3cf; */
  border-radius: 4px;
  font-size: 13px;

}

h5 {
  /* margin-top: 25px;
  font-weight: bold;
  font-size: 20px;
  text-transform: capitalize;
  color: #359c1b; */
}

h6{
  margin-top: 50px;
  font-weight: bold;
  font-size: 18px;
  text-transform: capitalize;
}


.mylist a{
  font-size: 22px !important;
  text-decoration: none;
  /* list-style: none; */
  border-bottom: 1px solid rgba(197, 197, 197, 0.541);
  /* padding: 10px; */
  display: block;
text-transform: capitalize;
color: black;
margin-bottom: 20px;

}


.mylist a:hover{
  color: #359c1b;


}

.mylist #readmorebtn:hover{
  color: rgb(197, 143, 43) !important;
  background-color: transparent;
  border: 1px solid rgb(197, 143, 43);


}

.mylist{
  /* background: rgb(240, 240, 240); */
  padding: 0px;
  padding-top: 5px;
  /* padding-left: 20px; */
  padding-bottom: 10px;
  text-transform: capitalize;



}

.mylist h5{
  font-size: 20px !important;
  /* padding-left: 10px; */
  font-weight: bold;
  margin-top: 25px;
  font-size: 20px;
  text-transform: capitalize;
  color: #359c1b;
}

#htmldiv p{
  padding: 0 !important;
  font-size: 16px !important;
margin-bottom: 10px;

  /* background-color: rgba(102, 83, 83, 0.116); */
}

#htmldiv2 span{
  padding: 0 !important;
  font-size: 14px !important;
margin-bottom: 10px;
color: rgb(58, 58, 58);
  
  /* background-color: rgba(102, 83, 83, 0.116); */
}


#htmldiv2 p{
  padding: 0 !important;
  font-size: 14px !important;
margin-bottom: 10px;
color: rgb(58, 58, 58);


  /* background-color: rgba(102, 83, 83, 0.116); */
}

#htmldiv span{
  padding: 0 !important;
  font-size: 16px !important;
margin-bottom: 10px;
  
  /* background-color: rgba(102, 83, 83, 0.116); */
}

#readmorebtn{
  width: 30%;
  font-size: 15px !important;
  margin-top: 30px;
  background-color: rgb(197, 143, 43);
  border: 0px solid rgb(197, 143, 43);
  
}

#relatedcontentlink{
  background: rgb(236, 236, 236);
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
}


#relatedcontentlink:hover{
  background-color: #359c1b44;

}